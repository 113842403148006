import { useQueries } from '@tanstack/react-query'
import { useContext, useMemo } from 'react'
import {
  MODULE_CONNECTORS,
  MODULE_COUNTRIES,
  MODULE_FLOW_RULES,
  MODULE_METADATA,
  MODULE_METHODS,
  MODULE_RESPONSE_CODES,
} from 'insights/constants/insights'
import {
  connectors,
  countries,
  flowRulesApplied,
  metadata,
  methods,
  responseCodes,
} from 'insights/constants/mocks'
import { InsightsFilterContext } from 'insights/contexts/InsightsFilterContext'
import { transformModuleData } from 'insights/helpers/transform-module'
import { typedEntries } from 'insights/helpers/typed-entries'
import { InsightsModule, InsightsModuleData } from 'insights/services/insights'

const moduleConfig = {
  methods: {
    queryKey: MODULE_METHODS,
    data: methods,
    filterKey: 'method',
  },
  connectors: {
    queryKey: MODULE_CONNECTORS,
    data: connectors,
    filterKey: 'paymentServiceId',
  },
  countries: {
    queryKey: MODULE_COUNTRIES,
    data: countries,
    filterKey: 'country',
  },
  flowRulesApplied: {
    queryKey: MODULE_FLOW_RULES,
    data: flowRulesApplied,
    filterKey: 'ruleId',
  },
  metadata: {
    queryKey: MODULE_METADATA,
    data: metadata,
    filterKey: 'metadata',
  },
  responseCodes: {
    queryKey: MODULE_RESPONSE_CODES,
    data: responseCodes,
    filterKey: 'errorCode',
  },
} as const

const getModuleData = async (
  module: InsightsModule
): Promise<InsightsModuleData[]> => {
  return Promise.resolve(moduleConfig[module as keyof typeof moduleConfig].data)
}

export const useModule = (modules: InsightsModule[]) => {
  const { filters } = useContext(InsightsFilterContext)

  const queries = useQueries({
    queries: typedEntries(moduleConfig).map(([module, config]) => ({
      queryKey: [
        config.queryKey,
        filters[config.filterKey],
        filters.period,
        filters.currency,
      ],
      queryFn: () => getModuleData(module),
      select: (data: InsightsModuleData[]) => transformModuleData(data, module),
      initialData: [],
      enabled: modules.includes(module),
    })),
  })

  const moduleData = queries.reduce(
    (acc, query, index) => {
      const module = Object.keys(moduleConfig)[
        index
      ] as keyof typeof moduleConfig
      acc[module] = query.data
      return acc
    },
    {} as Record<keyof typeof moduleConfig, InsightsModuleData[]>
  )

  return useMemo(
    () => ({
      methods: moduleData.methods,
      responseCodes: moduleData.responseCodes,
      connectors: moduleData.connectors,
      countries: moduleData.countries,
      flowRulesApplied: moduleData.flowRulesApplied,
      metadata: moduleData.metadata,
    }),
    [moduleData]
  )
}
