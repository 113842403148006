import { Grid, GridItem } from '@gr4vy/poutine-react'
import { Totals } from 'insights/components/Totals'
import { sevenDaysRange, sevenDaysSeries } from 'insights/constants/mocks'
import { LineChart, LineChartGettingStarted } from 'shared/components/LineChart'

export interface DataVisualizationProps {
  showGettingStarted: boolean
}

export const DataVisualization = ({
  showGettingStarted,
}: DataVisualizationProps) => (
  <Grid>
    <GridItem gridColumn="span 2">
      <Totals showGettingStarted={showGettingStarted} />
    </GridItem>
    <GridItem gridColumn="span 10">
      {showGettingStarted ? (
        <LineChartGettingStarted />
      ) : (
        <LineChart
          loading={false}
          data={sevenDaysRange}
          series={sevenDaysSeries}
          numberFormat="decimalCompact"
          showLegend={false}
        />
      )}
    </GridItem>
  </Grid>
)
