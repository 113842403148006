import { Box, Flex, Heading, Stack } from '@gr4vy/poutine-react'
import { ColorBlue60, ColorGray60 } from '@gr4vy/poutine-tokens'
import { capitalize } from 'lodash'
import { useContext } from 'react'
import { Period } from 'home/services/health-dashboard'
import { InsightsFilterContext } from 'insights/contexts/InsightsFilterContext'
import currencyFormat from 'shared/helpers/currency-format'
import { getSeriesNameByPeriod } from 'shared/helpers/line-chart'
import { getPercentageTrend } from 'shared/helpers/line-chart-format'
import { DataSetIndicator } from './DataSetIndicator'

interface TotalsProps {
  showGettingStarted?: boolean
}

export const Totals = ({ showGettingStarted }: TotalsProps) => {
  const {
    filters: { currency, filterBy, period },
  } = useContext(InsightsFilterContext)
  const currencyOptions: Intl.NumberFormatOptions = {
    currency: currency,
    currencyDisplay: 'narrowSymbol',
  }

  return (
    <Stack gap={16}>
      <Heading level={3}>
        {capitalize(filterBy?.replace(/([A-Z])/g, ' $1'))}
      </Heading>
      <Stack gap={32}>
        <Stack gap={4}>
          <DataSetIndicator color={ColorBlue60}>Current</DataSetIndicator>
          <Stack gap={8}>
            <Heading level={4}>
              {currencyFormat(showGettingStarted ? 0 : 285700, currencyOptions)}
            </Heading>
            <Flex gap={8} alignItems="center">
              {showGettingStarted
                ? getPercentageTrend('volume', 0)
                : getPercentageTrend('volume', -1)}
              {getSeriesNameByPeriod(period as Period)?.previous}
            </Flex>
          </Stack>
        </Stack>
        <Box>
          <DataSetIndicator color={ColorGray60}>Previous</DataSetIndicator>
          <Stack gap={4}>
            <Heading level={4}>
              {currencyFormat(showGettingStarted ? 0 : 263600, currencyOptions)}
            </Heading>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}
