import { Table } from '@gr4vy/poutine-react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { InsightsModuleData } from 'insights/services/insights'
import { useKeyboardListNavigation } from 'shared/hooks/use-keyboard-list-navigation'
import styles from './DataTable.module.scss'

interface DataTableProps {
  columns: ColumnDef<InsightsModuleData>[]
  data: InsightsModuleData[]
  onRowKeyToggle?: (key: string, isSelected: boolean) => void
  selectedRowKey?: string
}

export function DataTable({
  columns,
  data,
  onRowKeyToggle,
  selectedRowKey,
}: DataTableProps) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const { onKeyDown } = useKeyboardListNavigation()

  useEffect(() => {
    setRowSelection(selectedRowKey ? { [selectedRowKey]: true } : {})
  }, [selectedRowKey])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.key,
  })

  return (
    <Table>
      <Table.Header className={styles.header}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <Table.HeaderCell key={header.id} className={styles.cell}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeaderCell>
              )
            })}
          </Table.Row>
        ))}
      </Table.Header>
      <Table.Body>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <Table.Row
              key={row.id}
              data-state={
                table.getIsSomeRowsSelected()
                  ? row.getIsSelected()
                    ? 'selected'
                    : 'disabled'
                  : undefined
              }
              onClick={() => {
                row.toggleSelected()
                if (onRowKeyToggle) {
                  onRowKeyToggle(row.original.key, !row.getIsSelected())
                }
              }}
              className={styles.clickableRow}
              onKeyDown={onKeyDown}
              tabIndex={0}
            >
              {row.getVisibleCells().map((cell) => (
                <Table.BodyCell key={cell.id} className={styles.cell}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.BodyCell>
              ))}
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.EmptyCell colSpan={columns.length}>
              No results.
            </Table.EmptyCell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}
