import { ColorBlue60, ColorGray70 } from '@gr4vy/poutine-tokens'
import { addDays, addHours } from 'date-fns'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { SeriesOption } from 'echarts'
import { Tab } from 'home/constants/health-dashboard'
import {
  HealthDashboardSeriesTransactionsData,
  HealthDashboardSeriesVolumeData,
  HealthDashboardTotalTransactions,
  HealthDashboardTotalVolume,
  HealthDashboardTransactionsSeries,
  HealthDashboardVolumeSeries,
  Period,
} from 'home/services/health-dashboard'
import {
  defaultSeriesSettings,
  LineChartProps,
} from 'shared/constants/line-chart-config'
import {
  generateDayRange,
  generateHourRange,
  getDuration,
  getSeriesNameByPeriod,
  nullifyZeroedData,
} from 'shared/helpers/line-chart'
dayjs.extend(duration)

interface GenerateSeriesProps {
  series:
    | HealthDashboardVolumeSeries
    | HealthDashboardTransactionsSeries
    | undefined
  period: Period
}

export const generateSeries = ({
  series,
  period,
}: GenerateSeriesProps): Record<Tab, SeriesOption[]> => {
  if (series) {
    const currentLabelName = getSeriesNameByPeriod(period).current
    const previousLabelName = getSeriesNameByPeriod(period).previous

    const transactionsCurrent =
      'transactionsAccepted' in series.current ? series.current : undefined
    const transactionsPrevious =
      'transactionsAccepted' in series.previous ? series.previous : undefined
    const volumeCurrent =
      'transactionsAuthorized' in series.current ? series.current : undefined
    const volumePrevious =
      'transactionsAuthorized' in series.previous ? series.previous : undefined

    return {
      volume: [
        {
          name: currentLabelName,
          data: volumeCurrent?.transactionsAuthorized,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: volumePrevious?.transactionsAuthorized,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      transactions: [
        {
          name: currentLabelName,
          data: transactionsCurrent?.transactionsAccepted,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: transactionsPrevious?.transactionsAccepted,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      authRate: [
        {
          name: currentLabelName,
          data: series.current.transactionsAuthorizedRate,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: series.previous.transactionsAuthorizedRate,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      refunds: [
        {
          name: currentLabelName,
          data: series.current.refunds,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: series.previous.refunds,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
    }
  }

  return {
    volume: [],
    transactions: [],
    authRate: [],
    refunds: [],
  }
}

const extractDataFromSeries = (
  series: HealthDashboardVolumeSeries | HealthDashboardTransactionsSeries
) => {
  const [timestamp, period] = series.current.firstInterval.split('/')
  const length =
    'transactionsAuthorized' in series.current
      ? series.current.transactionsAuthorized.length
      : series.current.transactionsAccepted.length

  return { timestamp, length, duration: getDuration(period, length) }
}

export const generateDateRange = (
  series:
    | HealthDashboardVolumeSeries
    | HealthDashboardTransactionsSeries
    | undefined
): LineChartProps['data'] => {
  if (series?.current.firstInterval) {
    const { timestamp, length, duration } = extractDataFromSeries(series)

    return duration.days
      ? generateDayRange(timestamp, length)
      : generateHourRange(timestamp, length)
  }

  return []
}

export const generateTransactionsDateRange = (
  series: HealthDashboardVolumeSeries | HealthDashboardTransactionsSeries
) => {
  const { timestamp, duration } = extractDataFromSeries(series)

  const afterCreatedAt = new Date(timestamp).toISOString()
  const beforeCreatedAt = duration.hours
    ? addHours(afterCreatedAt, duration.hours).toISOString()
    : addDays(afterCreatedAt, duration.days).toISOString()

  return {
    afterCreatedAt,
    beforeCreatedAt,
  }
}

const transformZeroedSeriesData = <
  T extends
    | HealthDashboardSeriesVolumeData
    | HealthDashboardSeriesTransactionsData,
>(
  data: T
) => {
  const modifiedData = { ...data, refunds: nullifyZeroedData(data.refunds) }

  return 'transactionsAuthorized' in data
    ? {
        ...modifiedData,
        transactionsAuthorized: nullifyZeroedData(data.transactionsAuthorized),
      }
    : {
        ...modifiedData,
        transactionsAccepted: nullifyZeroedData(data.transactionsAccepted),
      }
}

export const transformSeriesData = <
  T extends HealthDashboardTotalVolume | HealthDashboardTotalTransactions,
>(
  data: T
) => ({
  ...data,
  series: {
    ...data.series,
    current: transformZeroedSeriesData(data.series.current),
    previous: transformZeroedSeriesData(data.series.previous),
  },
})
