import { Flex, GridItem, Icon, Stack, Tooltip } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { DataVisualization } from 'insights/components/DataVisualization'
import { Module } from 'insights/components/Module'
import { Section } from 'insights/components/Section'
import { InsightsFilterContext } from 'insights/contexts/InsightsFilterContext'
import { useModule } from 'insights/hooks/use-module'
import { PageLayout } from 'shared/components/PageLayout'
import { Selector } from 'shared/components/Selector/Selector'
import { pathToInsights } from 'shared/paths'

type InsightsPageProps = {
  title: string
}

const getTabs = (merchantAccountId?: string) =>
  merchantAccountId
    ? [
        {
          title: 'Authorization',
          url: pathToInsights.authorization(merchantAccountId),
        },
      ]
    : []

const InsightsPage = ({ title }: InsightsPageProps) => {
  const { merchantAccountId } = useParams<{ merchantAccountId: string }>()
  const {
    methods,
    responseCodes,
    connectors,
    countries,
    flowRulesApplied: flowRules,
    metadata,
  } = useModule([
    'methods',
    'responseCodes',
    'connectors',
    'countries',
    'flowRulesApplied',
    'metadata',
  ])

  return (
    <PageLayout title={title} subNavigation={getTabs(merchantAccountId)}>
      <Stack flexDirection="column" gap={56}>
        <Selector
          currencies={[]}
          filterByOptions={['volume', 'transactions', 'authRate']}
          filterContext={InsightsFilterContext}
        />
        <DataVisualization showGettingStarted={false} />
        <Section title="Request">
          <GridItem gridColumn="span 4">
            <Module
              filterKey="errorCode"
              title="Response codes"
              data={responseCodes}
            />
          </GridItem>
          <GridItem gridColumn="span 4">
            <Module filterKey="method" title="Methods" data={methods} />
          </GridItem>
          <GridItem gridColumn="span 4">
            <Module
              filterKey="metadata"
              title={
                <Flex alignItems="center" gap={4}>
                  Metadata
                  <Tooltip content="Top 25 Metadata in descending order">
                    <Icon name="info" size="small" />
                  </Tooltip>
                </Flex>
              }
              data={metadata}
            />
          </GridItem>
        </Section>
        <Section title="Countries">
          <GridItem gridColumn="span 4">
            <Module filterKey="country" title="Country" data={countries} />
          </GridItem>
          <GridItem gridColumn="span 4">
            <Module
              filterKey="paymentServiceId"
              title="Connectors"
              data={connectors}
            />
          </GridItem>
          <GridItem gridColumn="span 4">
            <Module
              filterKey="ruleId"
              title="Flow rule applied"
              data={flowRules}
            />
          </GridItem>
        </Section>
      </Stack>
    </PageLayout>
  )
}

export default InsightsPage
